<template>
  <span>
    <i class="fa fa-map" aria-hidden="true"></i>
    &#32;
    <a :href="url">{{ area.nickname }}<slot></slot></a>
  </span>
</template>

<script>
export default {
  props: ["area", "module"],
  computed: {
    url: function() {
      return "area/" + this.area._id;
    },
  },
};
</script>

<style></style>
