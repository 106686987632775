import Vue from 'vue'

import URLSearchParams from '@ungap/url-search-params'
window.URLSearchParams = URLSearchParams

import VueQrcode from '@chenfengyuan/vue-qrcode';
Vue.component(VueQrcode.name, VueQrcode);

import Papa from 'papaparse'
window.Papa = Papa

//import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'
// Global registration
//Vue.component('vue-bootstrap-typeahead', VueBootstrapTypeahead)