<template>
  <!--
        Traffic Device configuration
    -->
  <div class="editor-device-traffic">
    <fieldset style="margin-top: 40px">
      <legend>Traffic</legend>

      <div class="row">
        <div class="col-md-12">
          <h4><em class="text-info">Directions</em></h4>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-6">
          <label for="ltrname">Left to Right Direction Name:</label>
          <input
            id="ltrname"
            type="text"
            class="form-control"
            v-model.trim="device.traffic.ltr_name"
          />
          <span
            class="text-danger"
            v-text="errors['traffic.ltr_name']"
          ></span
          >&nbsp;
        </div>

        <div class="col-12 col-md-6 col-lg-3">
          <label>Status:</label>
          <div class="form-check p-0">
            <input
              id="ltrenabled"
              type="checkbox"
              v-model="device.traffic.ltr_enabled"
            />
            <label
              for="ltrenabled"
              class="form-check-label"
              style="line-height: 2.5em;"
            >
              &nbsp;Enabled
            </label>
            <br />
            <span
              class="text-danger"
              v-text="errors['traffic.ltr_enabled']"
              >&nbsp;</span
            >&nbsp;
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-6">
          <label for="rtlname">Right to Left Direction Name:</label>
          <input
            id="rtlname"
            type="text"
            class="form-control"
            v-model.trim="device.traffic.rtl_name"
          />
          <span class="text-danger" v-text="errors['traffic.rtl_name']"
            >&nbsp;</span
          >&nbsp;
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <label>Status:</label>
          <div class="form-check p-0">
            <input
              id="rtlenabled"
              type="checkbox"
              v-model="device.traffic.rtl_enabled"
            />
            <label
              class="form-check-label"
              for="rtlenabled"
              style="line-height: 2.5em;"
            >
              &nbsp;Enabled
            </label>
            <br />
            <span
              class="text-danger"
              v-text="errors['traffic.rtl_enabled']"
              >&nbsp;</span
            >&nbsp;
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
module.exports = {
  props: ["device", "errors"],
  data: function() {
    return {};
  },
};
</script>

<style></style>
