<template>
    <a v-bind:href="url">{{device.nickname}}<slot></slot></a>
</template>

<script>
    export default {
        props: ['device', 'module'],
        computed: {
            url: function(){ 
                return GLOBALS.makeDeviceURL(this.device, this.module) 
            }
        }
    }
</script>

<style>
    
</style>