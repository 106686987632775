<template>
  <span>
    <i class="fa fa-th" aria-hidden="true"></i>
    &#32;
    <a :href="url">{{ carpark.nickname }}<slot></slot></a>
  </span>
</template>

<script>
export default {
  props: ["carpark", "module"],
  computed: {
    url: function() {
      return "carpark/" + this.carpark._id;
    },
  },
};
</script>

<style></style>
