<template>
  <!--sc-map-housing-->
  <div v-bind:style="{ height: height }">
    <l-map
      ref="map"
      :zoom="zoom"
      :maxZoom="map.maxZoom"
      :center="map.center"
      v-bind:style="{ height: height }"
      :bounds="map.bounds"
    >
      <l-tile-layer :url="map.baseMap" :attribution="map.attrib"></l-tile-layer>

      <v-marker-cluster :options="clusterOpts">
        <l-marker
          v-for="d in other_properties"
          v-bind:key="d._id"
          :lat-lng="d.ll"
          :options="{ entity: d }"
          :icon="d.marker"
          @click="onClick(d)"
        >
          <l-popup v-if="d.type.indexOf('property') > -1"
            ><sc-popup-content :device="d" :module="module"></sc-popup-content
          ></l-popup>
        </l-marker>
      </v-marker-cluster>

      <template v-if="mode == 'devices'">
        <l-marker
          v-for="d in other_devices"
          v-bind:key="d._id"
          :lat-lng="d.ll"
          :icon="d.marker"
        >
          <l-popup
            ><sc-popup-content :device="d" :module="module"></sc-popup-content
          ></l-popup>
        </l-marker>
      </template>

      <l-marker v-if="selected" :lat-lng="selected.ll" :icon="selected.marker">
        <l-popup
          ><sc-popup-content
            :device="selected"
            :module="module"
          ></sc-popup-content
        ></l-popup>
      </l-marker>
    </l-map>

    <sc-modal v-if="selected_group" @close="selected_group = null" :size="'lg'">
      <div slot="title">Group: {{ selected_group.nickname }}</div>
      <div slot="body">
        <div class="row">
          <div
            class="col-3"
            v-for="prop in selected_group.properties"
            :key="prop._id"
            style="padding-left: 4px; padding-right: 4px; margin-bottom:5px"
          >
            <div
              style="border: 1px solid #cccccc; border-radius: 2px;
                                padding: 3px;
                                box-shadow: #dddddd 2px 2px 4px;
                                font-size: 0.8em"
            >
              <a v-bind:href="prop.link">
                <i
                  v-bind:style="{ color: prop.colour }"
                  class="fa fa-circle"
                ></i
                >&nbsp;{{ prop.nickname }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </sc-modal>
  </div>
</template>

<script>
import {
  L,
  LMap,
  LTileLayer,
  LMarker,
  LPopup,
  LCircle,
  LCircleMarker,
} from "vue2-leaflet";
import Vue2LeafletMarkercluster from "vue2-leaflet-markercluster";
import { ExtraMarkers } from "leaflet-extra-markers";
/**
 * for a device, return an object which is an extension of the device and some derived mapping properties
 */
function extendWithMappingPropertiesDevice(device) {
  if (!device) {
    return null;
  }

  debugger;

  var mapProps = {
    ll: L.latLng(device.position.lat, device.position.lon),
    marker: GLOBALS.createPushPinMarker(device),
  };
  return _.assign({}, device, mapProps);
}

function extendWithMappingPropertyGroups(prop_group, name, module_key) {
  var group_data = GLOBALS.getPropertyGroupMetadata(prop_group); // get the marker and group worst level

  var mapProps = {
    nickname: name,
    enabled: true,
    ll: L.latLng(prop_group[0].position.lat, prop_group[0].position.lon),
    group_level: group_data.level,
    marker: group_data.marker,
    properties: _.map(prop_group, function(p) {
      p.colour = GLOBALS.deviceColor(p);
      p.link = GLOBALS.makeDeviceURL(p, module_key);
      return p;
    }),
    type: "group",
  };

  return mapProps;
}

function extendWithMappingPropertiesHousing(device) {
  if (!device) {
    return null;
  }

  var mapProps = {
    ll: L.latLng(device.position.lat, device.position.lon),
    marker: GLOBALS.createMiniCircleMarker(device),
  };
  return _.assign({}, device, mapProps);
}

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LCircle,
    LCircleMarker,
    "v-marker-cluster": Vue2LeafletMarkercluster,
  },
  props: {
    mode: {
      type: String,
      required: false,
      default: "device",
    },
    module: String,
    device: Object,
    devices: Array,
    property: Object,
    properties: Array,
    signal: String,
    height: {
      type: String,
      required: false,
      default: "500px",
    },
    zoom: {
      type: Number,
      required: false,
      default: 10,
    },
  },
  methods: {
    onShow: function() {
      var vm = this;
      vm.setBounds();
      setTimeout(function() {
        vm.$refs.map.mapObject.invalidateSize();
      }, 200);
    },
    setBounds: function() {
      var vm = this;
      var bounds = L.latLngBounds();
      var didsetbounds = false;
      if (vm.selected) {
        didsetbounds = true;
        bounds.extend(vm.selected.ll);
      }
      if (vm.other_devices) {
        _.each(vm.other_devices, (d) => {
          didsetbounds = true;
          bounds.extend(d.ll);
        });
      }
      if (vm.other_properties) {
        _.each(vm.other_properties, (p) => {
          didsetbounds = true;
          //var ll = L.latLng(p.position.lat, p.position.lon)
          bounds.extend(p.ll);
        });
      }

      if (didsetbounds) {
        vm.map.bounds = bounds;
        return true;
      }
      return false;
    },
    setCenter: function() {
      var vm = this;
      if (vm.device) {
        vm.map.center = L.latLng(
          this.device.position.lat,
          this.device.position.lon
        );
      } else {
        var c = window.PAGE.instance.mapCenter;
        vm.map.center = L.latLng(c[0], c[1]);
      }
    },
    onClick: function(p) {
      var vm = this;
      if (p.type == "group") {
        vm.selected_group = p;
      }
    },
    group_all_properties: function() {
      var vm = this;
      if (!vm.properties || vm.properties.length == 0) {
        return [];
      }

      var result = [];

      _(vm.properties)
        .filter(function(d) {
          if (!vm.property) {
            // if there is no selected single property, then allow this in the group if it has a position.
            return !!d.position;
          }

          if (d._id != vm.property._id) {
            // if there is a selected single property, then allow this in the group if it is not the selcted one, and has a position.
            return !!d.position;
          }

          return false; // don't use if same as selected or no position.
        })
        .groupBy("group")
        .forEach(function(prop_list, group_name) {
          if (_.includes(["undefined", null, "null", ""], group_name)) {
            _.each(prop_list, function(p) {
              result.push(extendWithMappingPropertiesHousing(p));
            });
          } else {
            result.push(
              extendWithMappingPropertyGroups(prop_list, group_name, vm.module)
            );
          }
        });

      return result;
    },
  },
  mounted: function() {
    var vm = this;

    if (vm.selected) {
      vm.setCenter();
    } else {
      vm.setBounds();
    }

    if (!vm.signal) {
      vm.onShow();
    }
    Vue.eventBus.$on(vm.signal, vm.onShow);
  },
  data: function() {
    var vm = this;
    return {
      message: "Loading...",
      map: {
        baseMap:
          "https://tile.thunderforest.com/neighbourhood/{z}/{x}/{y}{r}.png?apikey=6c2d51dcdb914d11aecb4eb0b83260b4",
        attrib:
          'Maps © <a href="https://www.thunderforest.com">Thunderforest</a>, Data © <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
        bounds: null,
        center: [53, -4],
        maxZoom: 18,
      },
      selected_group: null,
      clusterOpts: {
        disableClusteringAtZoom: 17,
        iconCreateFunction: GLOBALS.makeClusterIcon,
        spiderfyOnMaxZoom: false,
      },
      circOpts: {
        color: "white",
        fillOpacity: 0.4,
        weight: 2,
        radius: 4,
      },
      selected: extendWithMappingPropertiesDevice(vm.property),
      other_properties: vm.group_all_properties(),
    };
  },
};
</script>

<style></style>
