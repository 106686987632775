<template>
  <!--
        Footfall Device configuration
    -->
  <div class="editor-device-footfall">
    <fieldset style="margin-top: 40px">
      <legend>Footfall</legend>

      <div class="row">
        <div class="col-md-12">
          <h4><em class="text-info">Counters</em></h4>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-lg-6">
          <label for="counter1name">Counter 1 Name:</label>
          <input
            id="counter1name"
            type="text"
            class="form-control"
            v-model.trim="device.footfall.counter1_name"
          />
          <span
            class="text-danger"
            v-text="errors['footfall.counter1_name']"
          ></span
          >&nbsp;
        </div>

        <div class="col-12 col-md-6 col-lg-3">
          <label>Status:</label>
          <div class="form-check p-0">
            <input
              id="counter1enabled"
              type="checkbox"
              v-model="device.footfall.counter1_enabled"
            />
            <label
              for="counter1enabled"
              class="form-check-label"
              style="line-height: 2.5em;"
            >
              &nbsp;Enabled
            </label>
            <br />
            <span
              class="text-danger"
              v-text="errors['footfall.counter1_enabled']"
              >&nbsp;</span
            >&nbsp;
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-6">
          <label for="counter2name">Counter 2 Name:</label>
          <input
            id="counter2name"
            type="text"
            class="form-control"
            v-model.trim="device.footfall.counter2_name"
          />
          <span class="text-danger" v-text="errors['footfall.counter2_name']"
            >&nbsp;</span
          >&nbsp;
        </div>
        <div class="col-12 col-md-6 col-lg-3">
          <label>Status:</label>
          <div class="form-check p-0">
            <input
              id="counter2enabled"
              type="checkbox"
              v-model="device.footfall.counter2_enabled"
            />
            <label
              class="form-check-label"
              for="counter2enabled"
              style="line-height: 2.5em;"
            >
              &nbsp;Enabled
            </label>
            <br />
            <span
              class="text-danger"
              v-text="errors['footfall.counter2_enabled']"
              >&nbsp;</span
            >&nbsp;
          </div>
        </div>
      </div>
    </fieldset>
  </div>
</template>

<script>
module.exports = {
  props: ["device", "errors"],
  data: function() {
    return {};
  },
};
</script>

<style></style>
