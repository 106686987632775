

var re_dev_eui = /^([0-9a-fA-F]{2})[-: _]?([0-9a-fA-F]{2})[-: _]?([0-9a-fA-F]{2})[-: _]?([0-9a-fA-F]{2})[-: _]?([0-9a-fA-F]{2})[-: _]?([0-9a-fA-F]{2})[-: _]?([0-9a-fA-F]{2})[-: _]?([0-9a-fA-F]{2})$/
/* returns dev eui lowercased without spacing, or throws 'no_match' if doesn't match something we can use */
function dev_eui_format(input){
    var matches = re_dev_eui.exec(input.toLowerCase())
    if(!matches){
        throw 'no_match'
    }
    if(matches.length != 9){
        throw 'no_match'
    }
    matches.splice(0,1)
    return matches.join('')
}

export default {
    dev_eui_format: dev_eui_format
}

// de-focus buttons by adding v-blur 
import Vue from 'vue'
Vue.directive('blur', {
    inserted: function (el) {
      el.onfocus = (ev) => ev.target.blur()
    }
  });
  