var tempus_data = {
    titles: {
        POOR_VENT: "Poor Ventilation",
        EXCESS_MOISTURE: "Excessive moisture generation in the property",
        LOW_TEMP: "Low internal air temperature",
        POOR_VENT_HIGH_TEMP: "Poor Ventilation and elevated internal air temperature",
        EMPTY_PROPERTY: "Possible empty property"
    }
    ,
    causes: {
        POOR_VENT: [
            { 
                title: "Poor forced ventilation performance",
                desc: 
                    "Check fan performance ensuring humidity is effectively being controlled in ventilated area. Checks to include vent ducting to the exterior."
                ,
                kbs: ["KB-1","KB-2","KB-3","KB-5","KB-7"]   
            }
            ,
            { 
                title: "Ineffective flow of make-up air",
                desc: "Check undercut to doors etc. A free flow of make-up air is required to replace the air removed by the forced ventilation, ideally this should come from the rest of the property into the ventilated area."
                ,
                kbs: ["KB-4","KB-5"]   
            }
            ,
            { 
                title: "Low passive ventilation levels",
                desc: "A level of passive ventilation is required to control internal humidity levels, check trickle vents etc. and provide education as required to the occupants."
                ,
                kbs: ["KB-7"]   
            }
        ]
        ,
        EXCESS_MOISTURE: [
            { 
                title: "Excessive drying of laundry etc. in the property combined with reduced ventilation",
                desc: "Provide advice to occupant with regard to moisture generation in the property and its control."
                ,
                kbs: ["KB-8"]   
            }
            
        ]
        ,
        LOW_TEMP: [
            { 
                title: "Ineffective heating of the property",
                desc: "Possible issues with heating system function and/or occupants’ control – possible issues with regard to fuel poverty etc."
                ,
                kbs: ["KB-9","KB-10","KB-11"]   
            }
            
        ]
        ,
        POOR_VENT_HIGH_TEMP: [
            { 
                title: "Elevation internal temperature reducing relative humidity levels.",
                desc: "This can drive high humidity levels in cooler parts of the property Check ventilation performance through property and review heating system operation."
                ,
                kbs: ["KB-9", "KB-11"]   
            }
            
        ]
        ,
        EMPTY_PROPERTY: [
            { 
                title: "Low air temperature during the Winter period.",
                desc: "",
                kbs: []   
            }
            
        ]
    }
    ,
    kb_items: {
        "KB-1": {
            title: "Check function and flow rate of installed extraction units - compare with MOT",
            desc: "The performace of an installed extractor unit will be determined during an MOT. A re-check is recommended to ensure the flow rate is adequate for the size of the room once the unit has been switched off and an overrun is underway."
        }
        ,
        "KB-2": {
            title: "Confirm mode of operation and any tampering",
            desc: "Check the unit for any blockages, deliberate or natural and determine if the tenant isolates the device or simply does not use the extractor unit. Units that are manually operated will require manual operation by the tenant."
        }
        ,
        "KB-3": {
            title: "Check and record overrun after use (where applicable)",
            desc: "When testing the unit, an overrun is the time in minutes the unit will continue to function after it has been switched off. This aids dispelling moist air to the exterior after the room has been used."
        }
        ,
        "KB-4": {
            title: "Confirm door undercut to room is compliant with Part F of the current Building Regs",
            desc: "To ensure extractor fan units are effective make up air must be able to flow freely in to the ventilated area. To this end Part F of the Building Regulations requires a gap of 10mm."
        }
        ,
        "KB-5": {
            title: "Discuss with tenant mode of operation when installed in or next to a window",
            desc: "When a device is installed next to or in a window, for optimum performance, ideally the make-up air should be drawn from the rest of the property via the undercut to the door. This is known as make-up air and when it is drawn in through associated passive vents and windows it can have a detrimental impact on the extractor unit’s ability to clear damp air from the bathroom during and following the bathroom use."
        }
        ,
        "KB-6": {
            title: "Doors to moisture production areas removed",
            desc: "Where not installed, it is recommended doors should be fitted (with an appropriate undercut) to kitchen and bathrooms to assit the control of atmospheric moisture migration through the property."
        }
        ,
        "KB-7": {
            title: "Assess all passive ventilation including trickle vents and wall plates for cleanliness and function",
            desc: "Check all window trickle vents for being used, clean and free from any obstruction. Passive vent plates fitted to walls should be checked for the same plus, a thermal review will aid determining if the fixed plate is inadvertently making the wall colder than the surrounding areas."
        }
        ,
        "KB-8": {
            title: "Drying clothing in the property",
            desc: "Try not to dry laundry in the property. If there are no options available, always provide adequate ventilation by opening windows preferably providing a cross flow of air through the property. During the winter months when external temperatures are low or when security is an issue, drying small volumes of clothing in the bathroom with the door closed and bathroom extractor working would be the best option. Any tumble dryers should be used in accordance with manufacturers guidelines and vented to the exterior"
        }
        ,
        "KB-9": {
            title: "Contents against external walls",
            desc: "It is recognised that areas of a structure behind static contents placed agaisnt external walls i.e. beds, wardrobes etc, can restrict the natural air circulation behinds that lead to colder areas. This is turn can lead to the develoipment of condensation and mould when conditions prevail. It is recommended to move the furniture in the property where possible to prevent the build-up of areas of still cold air against exterior walls."
        }
        ,
        "KB-10": {
            title: "Balance of heating and ventilation",
            desc: "This refers to discussing with the tenant the importance of expelling moist air but also ensuring the internal temperature is maintained and can be achieved by periodic venting after moisture generating activities."
        }
        ,
        "KB-11": {
            title: "Is the heating utilised",
            desc: "Discuss with the tenant the importance of maintaining a standard level of warmth in the property. Utilising the heating system on an infrequent basis serves to warm the air when used resulting in a cooler structure. This in turn encourages the feeling of being cold. Consideration should be given to operating the heating at all times but on a controlled thermostat setting such that it will only operate when necessary."
        }
        ,
        "KB-12": {
            title: "Checking the heating system is operational",
            desc: "If the system is operate dby an on-demand boiler, check the pressure level and also discuss with the tenant if they are having to top-up the pressure. This is indiactive of a leak to the heating system. Visually check all accessible areas for any clear dampness prior to appointing a heating engineer."
        }
    }
}

function get_tempus_knowledge_status(spm)
{
    var tempus_scenarios = {
        active: [],
        general: []
    };
    var tempus_vp_diff = _.get(spm, 'models.tempus_vp_diff')
    var tempus_high_rh = _.get(spm, 'models.tempus_high_rh')
    var tempus_low_temp = _.get(spm, 'models.tempus_low_temp')

    if(!tempus_vp_diff){
        tempus_vp_diff = { status_level: 0}
    }
    if (!tempus_high_rh) {
        tempus_high_rh = { status_level: 0 }
    }
    if (!tempus_low_temp) {
        tempus_low_temp = { status_level: 0 }
    }

    var overall_level = tempus_vp_diff.status_level + tempus_high_rh.status_level + tempus_low_temp.status_level

    var overall_cssClass = SMART_PROPERTY.translateLevel2CSSClass(overall_level)

    if(tempus_vp_diff.status_level > 0
        &&
        tempus_high_rh.status_level > 0
        &&
        tempus_low_temp.status_level == 0
    ){
        tempus_scenarios.active.push({key: 'POOR_VENT', cssClass: overall_cssClass})
        tempus_scenarios.active.push({ key: 'EXCESS_MOISTURE', cssClass: overall_cssClass })
    } else {
        tempus_scenarios.general.push({key: 'POOR_VENT', cssClass: 'info'})
        tempus_scenarios.general.push({ key: 'EXCESS_MOISTURE', cssClass: 'info' })
    }

    if (tempus_vp_diff.status_level == 0
        &&
        tempus_high_rh.status_level > 0
        &&
        tempus_low_temp.status_level > 0
    ) {
        tempus_scenarios.active.push({ key: 'LOW_TEMP', cssClass: overall_cssClass })
    } else {
        tempus_scenarios.general.push({ key: 'LOW_TEMP', cssClass: 'info' }) 
    }
    


    if (tempus_vp_diff.status_level > 0
        &&
        tempus_high_rh.status_level == 0
        &&
        tempus_low_temp.status_level == 0
    ) {
        tempus_scenarios.active.push({ key: 'POOR_VENT_HIGH_TEMP', cssClass: overall_cssClass })
    } else {
        tempus_scenarios.general.push({ key: 'POOR_VENT_HIGH_TEMP', cssClass: 'info' })
    }

    if (tempus_vp_diff.status_level == 0
        &&
        tempus_high_rh.status_level == 0
        &&
        tempus_low_temp.status_level > 0
    ) {
        tempus_scenarios.active.push({ key: 'EMPTY_PROPERTY', cssClass: overall_cssClass })
    } else {
        tempus_scenarios.general.push({ key: 'EMPTY_PROPERTY', cssClass: 'info' })
    }
    
    return tempus_scenarios
}


export default {
    get_tempus_knowledge_status: get_tempus_knowledge_status
    ,data: tempus_data
}