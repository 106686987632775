<template>
    <!--
            Road Attributes
    -->
    <div class="editor-device-road" >
        <fieldset style="margin-top:40px;">
            <legend>Road</legend> 

            <div class="row">
                <div class="col-md-12"><h4><em class="text-info">Alarm</em></h4></div>
                <div class="col-md-4">
                    <label for="wmin">Warning Min: (&deg;C)</label>
                    <input id="wmin" class="form-control" type="number" step="0.01"
                           v-model.number="device.alarms.criteria.temperature['warning-min']"/>
                    <span class="errorInfo" v-text="errors['alarms.criteria.temperature.warning-min']"></span>
                </div>
                <div class="col-md-4">
                    <label for="wmax">Warning Max: (&deg;C)</label>
                    <input id="wmax" class="form-control" type="number" step="0.01"
                           v-model.number="device.alarms.criteria.temperature['warning-max']"/>
                    <span class="errorInfo" v-text="errors['alarms.criteria.temperature.warning-max']"></span>
                </div>
                <div class="col-md-12"></div>
                <div class="col-md-4">
                    <label for="cmin">Critical Min: (&deg;C)</label>
                    <input id="cmin" class="form-control" type="number" step="0.01"
                           v-model.number="device.alarms.criteria.temperature['critical-min']"/>
                    <span class="errorInfo" v-text="errors['alarms.criteria.temperature.critical-min']"></span>
                </div>
                <div class="col-md-4">
                    <label for="cmax">Critical Max: (&deg;C)</label>
                    <input id="cmax" class="form-control" type="number" step="0.01"
                           v-model.number="device.alarms.criteria.temperature['critical-max']"/>
                    <span class="errorInfo" v-text="errors['alarms.criteria.temperature.critical-max']"></span>
                </div>
            </div>
        </fieldset>
    </div>
</template>

<script>
export default {
    props: ['device', 'errors'],
    data: function() {
        return {}
    }
}
</script>

<style>
</style>