<style>
.heatmap {
  display: flex;
  flex-wrap: wrap;
  width: 384px;
}
.gridEyeTitle {
  width: 384px;
  text-align: center;
  padding-top: 6px;
}
.heatmap-item {
  height: 48px;
  text-align: center;
  width: 12.5%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.heatmap-item.isNaN {
  background-color: lightgray;
}
.heatmap-item.color-0 {
  background-color: #fff604;
}
.heatmap-item.color-1 {
  background-color: #ffd603;
}
.heatmap-item.color-2 {
  background-color: #ffbc03;
}
.heatmap-item.color-3 {
  background-color: #ffa203;
}
.heatmap-item.color-4 {
  background-color: #ff8803;
}
.heatmap-item.color-5 {
  background-color: #ff7303;
}
.heatmap-item.color-6 {
  background-color: #ff5e02;
}
.heatmap-item.color-7 {
  background-color: #ff4902;
}
.heatmap-item.color-8 {
  background-color: #ff3601;
}
.heatmap-item.color-9 {
  background-color: #ff1c01;
}
.heatmap-item.color-10 {
  background-color: #ff0000;
}
</style>

<template>
  <div>
    <div id="gridEyeContainer" class="heatmap" v-html="gridEyeHtml"></div>
    <div class="gridEyeTitle">{{ title }}</div>
  </div>
</template>

<script>
const ROTATE_0 = 0;
const ROTATE_90 = 1;
const ROTATE_180 = 2;
const ROTATE_270 = 3;

export default {
  props: {
    title: String,
    gridEyeData: Array,
    gridEyeRotation: { type: Number, default: 0 },
    isAdmin: { type: Boolean, default: false },
  },
  data: function() {
    return {};
  },
  computed: {
    gridEyeHtml: function() {
      var vm = this; // `this` points to the vm instance
      if (!vm.gridEyeData || vm.gridEyeData.length == 0) {
        return ""; //no data yet...
      }

      const temps = vm.gridEyeData.filter((temperature) => !isNaN(temperature));
      const gridEyeMin = Math.min(...temps);
      const gridEyeMax = Math.max(...temps);

      const gridValues = to2DArray(vm.gridEyeData, vm.gridEyeRotation);
      let boxes = [];

      for (let row = 0; row < 8; row++) {
        for (let col = 0; col < 8; col++) {
          const temperature = gridValues[row][col];
          let boxClass = "heatmap-item";

          if (isNaN(temperature)) {
            boxClass += ` isNaN`;
          } else {
            let heat = (temperature - gridEyeMin) / (gridEyeMax - gridEyeMin);
            boxClass += ` color-${heat.toFixed(1) * 10}`;
          }
          boxes.push(
            `<div class="${boxClass}"><div class="heatmap-item-text">${temperature}</div></div>`
          );
        }
      }

      return boxes.join("\n");
    },
  },
};

function to2DArray(flatArray, rotation) {
  if (!(flatArray instanceof Array)) {
    throw "Parameter is not an Array";
  }
  if (flatArray.length == 0) {
    return [];
  }
  if (rotation === undefined || rotation < ROTATE_0 || rotation > ROTATE_270) {
    throw "Rotation is not valid";
  }

  const size = Math.sqrt(flatArray.length);
  const matrix = new Array(size).fill(0).map(() => new Array(size).fill(0));
  for (let row = 0; row < size; row++) {
    for (let col = 0; col < size; col++) {
      const value = flatArray[row * size + col];
      let x, y;
      switch (rotation) {
        case ROTATE_0:
          y = row;
          x = col;
          break;
        case ROTATE_90:
          y = col;
          x = size - 1 - row;
          break;
        case ROTATE_180:
          y = size - 1 - row;
          x = size - 1 - col;
          break;
        case ROTATE_270:
          y = size - 1 - col;
          x = row;
          break;
      }
      matrix[y][x] = value;
    }
  }
  return matrix;
}
</script>
