<template>
  <select
    v-on:change="onSelect"
    v-model="selectedId"
    id="device-select"
    class="form-control"
    v-if="selectable && selectable.length > 0"
  >
    <option disabled value="--" selected>Select...</option>
    <option
      v-for="s in selectable"
      v-bind:value="s._id"
      :disabled="selected && selected._id == s._id"
      v-bind:key="s._id"
      >{{ s.nickname }}</option
    >
  </select>
</template>

<script>
export default {
  props: ["selectable", "selected", "module"],
  methods: {
    onSelect(event) {
      var vm = this;
      var device = _.find(this.selectable, function(d) {
        return d._id == vm.selectedId;
      });
      var url = GLOBALS.makeDeviceURL(device, vm.module);
      location.href = url;
    },
  },
  data: function() {
    return {
      selectedId: "--",
    };
  },
};
</script>

<style></style>
