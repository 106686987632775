<template>
    <!--
            Waste Attributes
    -->
    <div class="editor-device-waste" >
        <fieldset style="margin-top:40px;">
            <legend>Waste (BrighterBins)</legend>

            <div class="row">
                <div class="col-md-12"><h4><em class="text-info">Parameters</em></h4></div>
                <div class="col-md-4">
                    <label for="bmu">Max U/S Distance: (meters)</label>
                    <input id="bmu" class="form-control" type="number" step="0.01"
                           v-model.number="device.bin['max_dist']"/>
                    <span class="errorInfo" v-text="errors['bin.max_dist']"></span>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12"><h4><em class="text-info">Alarm</em></h4></div>
                <div class="col-md-4">
                    <label for="wmin">Warning: (%)</label>
                    <input id="wmin" class="form-control" type="number" step="0.01"
                           v-model.number="device.alarms.criteria.binmgmt.warning"/>
                    <span class="errorInfo" v-text="errors['alarms.criteria.binmgmt.warning']"></span>
                </div>
                <div class="col-md-4">
                    <label for="wmax">Critical: (%)</label>
                    <input id="wmax" class="form-control" type="number" step="0.01"
                           v-model.number="device.alarms.criteria.binmgmt.critical"/>
                    <span class="errorInfo" v-text="errors['alarms.criteria.binmgmt.critical']"></span>
                </div>
            </div>
        </fieldset>
    </div>
</template>

<script>
export default {
    props: ['device', 'errors'],
    data: function() {
        return {}
    }
}
</script>

<style>
</style>