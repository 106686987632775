<template>
    <div v-bind:style="{display: no_edit ? 'inline-block' : 'block'}">
        <span v-if="!editing">
            <span v-if="field.type == 'en_dis'">
                <span>{{valueText}}</span>
            </span>
            <span v-else-if="field.type == 'select'">
                <span v-if="field.value != null">{{valueText}}</span>
                <span v-if="field.value == null" class="text-muted" ><em>none</em></span>
            </span>

            <template v-else>
                <span v-if="field.value != null">{{field.value}}</span>
                <span v-if="field.value == null && field.default_value"><em>{{field.default_value}} (default)</em></span>
                <span v-if="field.value == null && !field.default_value" class="text-muted" ><em>none</em></span>
            </template>
            
            <a v-if="!no_edit" @click="edit()" class="text-warning" style="margin-left: 1em;"><i class="fa fa-edit"></i></a>
        </span>

        <span v-if="editing">
            <div class="input-group input-group-sm">
                <input v-if="field.type == 'text'" v-model="field.value" class="form-control" type="text">
                <input v-if="field.type == 'number'" v-model.number="field.value" class="form-control" type="number" v-bind:min="field.min" v-bind:max="field.max">
                <select v-if="field.type == 'en_dis'" v-model="field.value" class="form-control">
                    <option v-bind:value="true">{{ field.labels && field.labels[0] || 'Enabled'}}</option>
                    <option v-bind:value="false">{{ field.labels && field.labels[1] || 'Disabled'}}</option>
                </select>

                <select v-if="field.type == 'select'" v-model="field.value" class="form-control">
                    <option v-for="opt in field.options" v-bind:key="opt.key" v-bind:value="opt.key">{{opt.title}}</option>
                </select>

                <div class="input-group-append">
                    <button @click="save()" class="btn btn-outline-secondary" type="button" id="button-addon2">Save&nbsp;<i class="fa fa-save fa-fw"></i></button>
                    <button type="button" class="btn btn-outline-secondary dropdown-toggle dropdown-toggle-split" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <span class="sr-only">Toggle Dropdown</span>
                    </button>
                    <div class="dropdown-menu">
                    <a class="dropdown-item" @click="cancel()">Cancel</a>
                    <a class="dropdown-item" @click="reset()">Default</a>
                    </div>
                </div>
            </div>

            
        </span>
    </div>
</template>

<script>
    export default {
        props: ['device', 'field', 'type', 'no_edit'],
        computed: {
            url: function(){ 
                return GLOBALS.makeDeviceURL(this.device) 
            },
            valueText: function(){
                var vm = this;
                
                if(vm.field.type == 'select')
                {
                    var val = vm.field.value
                    var suffix = ""
                    if( val == null){
                        val = vm.field.default_value
                        suffix = " (default)"
                    }
                    for(var o=0;o<vm.field.options.length;o++)
                    {
                        if(vm.field.options[o].key == val){
                            return vm.field.options[o].title + suffix
                        }
                    }
                }
                
                if(vm.field.type == 'en_dis')
                {
                    var suffix = ""
                    var val = vm.field.value
                    if( val == null){
                        val = vm.field.default_value
                        suffix = " (default)"
                    }

                    var label = ""

                    if(val === null){
                        return "none"
                    }
                    
                    if(vm.field.labels){
                        label = val ? vm.field.labels[0] : vm.field.labels[1]
                    }
                    else {
                        label = val ? 'Enabled' : 'Disabled'
                    }
                    return label + suffix
                }

                return vm.field.value
            }
        },
        data: function(){
            return {
                cfg: {
                    url: window.SMARTPLACES.urls.apiv1_config.replacing({'entity_type':this.type, 'entity_id':this.device._id}).update
                },
                editing: false,
                old_value: null
            }
        }
        ,
        created: function(){
            //console.log(this.no_edit)
        },
        methods: {
            edit: function(){
                var vm = this;
                vm.old_value = vm.field.value;

                if(!vm.field.value && vm.field.default_value){
                    vm.field.value = vm.field.default_value
                }

                vm.editing = true;
            },
            cancel: function(){
                var vm = this;
                vm.field.value = vm.old_value;
                vm.editing = false;
            },
            reset: function(){
                var vm = this;
                vm.field.value = null;
                vm.save();
            },
            save: function(){
                var vm = this;
                var update = {}

                if(vm.field.value == null || _.isString(vm.field.value) && vm.field.value.length == 0){
                    if(!confirm("Reset value to default?")){
                        return
                    }
                }

                update[vm.field.key] = vm.field.value
                axios.post(vm.cfg.url, update)
                    .then((resp) => {
                        PNotify.success({ title:"Success", text: "Updated: \"" + vm.field.label + "\" OK!"  });
                        vm.editing = false;
                        vm.old_value = vm.field.value;
                    }).catch((err) => {
                        PNotify.error({ title:"Error Updating Parameter", text: err.response.data.message  });
                    })
            }
        }
    }
</script>

<style>
    i.clear-icon {
        position: absolute;
        top: 7px;
        right: 87px;
        z-index: 999;

    }
</style>