<template>
<div class="card border-dark">
    <div class="card-header bg-dark text-white">
        <i class="fa fa-edit fa-fw"></i> Notes &nbsp;&nbsp;&nbsp;
    </div>
    <!-- /.panel-heading -->
    <div class="card-body row">
        <div class="col-sm-4">
            <div class="card bg-light">
                <div class="card-body">
                    <textarea cols="20" rows="3" class="form-control" id="msg" name="msg" v-model="newNoteMsg" placeholder="New Note..."></textarea>
                    <br>
                    <button type="submit" @click.stop.prevent="submitNewNote($event)" class="btn btn-primary">
                        Save
                    </button>
                </div>
            </div>
        </div>

        <div class="col-sm-8">
            <ul class="list-group">
                <li class="list-group-item flex-column align-items-start" v-for="(note,$index) in notes" k-bind:key="note.id">
                    <p class="mb-1 font-weight-bold" style="font-size: 1.2em;"><small class="text-muted">#{{ notes.length - $index }}</small> {{ note.msg }}</p>
                    <small><i class="fa fa-user"></i>&nbsp;{{note.author}}</i></small>  | <small>{{ note.created_on * 1000 | fromNow }}</small> | <small><a href="#" v-on:click.stop.prevent="del(note, $index)">Delete</a></small>
                </li>
              </ul>
        </div>



    </div>
</div>
</template>

<script>

    export default {
        props: ['device'],
        methods: {
            submitNewNote($event){
                var vm = this;
                if(!vm.newNoteMsg){
                    return;
                }

                axios.post(vm.cfg.urls.add, {msg: vm.newNoteMsg}).then(function(resp){
                    vm.notes.splice(0, 0, resp.data);
                    vm.newNoteMsg = null;
                })
            },
            del(note, idx){
                var vm = this;
                axios.delete(vm.cfg.urls.delete_for_index(note._id)).then(function(resp){
                    vm.notes.splice(idx, 1);
                })
            },
            load: function(){
                var vm = this;
                axios.get(vm.cfg.urls.get).then(function(resp){
                    Vue.set(vm, 'notes', resp.data)
                })
            }
        }
        ,
        created: function(){
            var vm = this;
            Vue.eventBus.$on("on-tab-notes", function(){
                console.log("HERE HERE");
                vm.load()

            })
        }
        ,data: function(){
            return {
                newNoteMsg: null,
                notes: [],
                cfg: {
                    urls: window.SMARTPLACES.urls.notes.get_for(this.device._id)
                }
            }
        }
    }

</script>

<style>
</style>