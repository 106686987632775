//console.log("sc-common")

function make_client(id, urls)
{
    var toURLParams = function(params){
        return _.reduce(_.map(params, 
                        function (v, k) { return k + '=' + v }), 
                    function (a, b) { return a + (a == '?' ? '' : '&') + b }, '?') 
    }

    var toURL = function(type, params){
        return urls[type] + toURLParams(params)
    }

    var raw_fetcher = function (type, params) {
        return axios.get( toURL(type, params) )
    };

    var caching_fetcher = _.memoize(raw_fetcher, toURL);

    return function (type, cfg) {
        return caching_fetcher(type, cfg)
            .then(function (resp) {
                return resp.data; // unpack response
            });
    }
}

class Fetcher {
    constructor(scheme, id) {
        if(typeof(scheme) === 'string'){
            this.urls = window.SMARTPLACES.urls[scheme].get_for(id);
        } else {
            this.urls = scheme // ready urls
        }
        
        this.id = id;
        this.fetch = make_client(this.id, this.urls);
        var curried = _.curry(this.fetch)
        this.latest = curried('feed_latest')
        this.timeseries = curried('feed_timeseries')
    }
}

class DataClient {
    constructor() {
        this.fetchers = {};
    }

    for(scheme, id) {
        var fetcher_key = scheme + "_" + id;
        if (typeof this.fetchers[fetcher_key] !== 'undefined') {
            //console.log(typeof (this.fetchers[fetcher_key]));
            return this.fetchers[fetcher_key];
        }
        else {
            var new_fetcher = new Fetcher(scheme, id);
            this.fetchers[fetcher_key] = new_fetcher;
            return new_fetcher;
        }
    };
}

export default {
    version: '1.2.3'
    ,dataClient: new DataClient()
}