<template>
    <div>
        <datepicker 
            v-model="cfg.reference_date" 
            :inline="true" 
            :monday-first="true"
            :bootstrap-styling="true"
            :highlighted="ui.highlight"
            :calendar-class="'sc-date-picker'"
            @selected="setDate"
        >
        </datepicker>
    </div>
</template>

<script>
export default {
        props: ['cfg'], // a PSP.DateFunctions.DateRangeCfg() // defined in: webapp/static/components/date_component_functions.js
        data: function(){
            var vm = this;
            return {
                ui: {
                    highlight: {
                        to: vm.cfg.start_date,
                        from: vm.cfg.end_date
                    } 
                }
            }
        }
        ,
        methods: {
            setDate(input){
                this.cfg.setDate(input)
            }
        },
        watch: {
            'cfg.start_date': function(d){ this.ui.highlight.from = d },
            'cfg.end_date': function(d){ this.ui.highlight.to = d }
        }
}
</script>

<style>
/* see : codebase/src/webapp/src/style/features.less */
</style>