<template>
    <!--
            MetOffice Attributes
    -->
    <div class="editor-device-rain" >
        <fieldset style="margin-top:40px;">
            <legend>Weather / MetOffice Station API</legend>

            <div class="row">
                <div class="col-md-12"><h4><em class="text-info">Parameters</em></h4></div>
                <div class="col-md-12 bg-warning">
                    <span class="text-danger">The dev_eui must be the MetOffice station number</span>
                    <br>
                    <em>See List from MetOffice</em>
                    <a target="_blank" href="http://datapoint.metoffice.gov.uk/public/data/val/wxobs/all/xml/sitelist?key=eb8d17da-7bad-4327-a91c-8359b07874a4">http://datapoint.metoffice.gov.uk/public/data/val/wxobs/all/xml/sitelist</a>
                </div>
            </div>
        </fieldset>
    </div>
</template>

<script>
export default {
    props: ['device', 'errors'],
    data: function() {
        return {}
    }
}
</script>

<style>
</style>