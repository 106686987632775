<template>
    <div style="display: flex; justify-content: center;">
        <div style="display:flex; align-items:center; padding: 1rem; border: 1px solid #a39f9f; border-radius: 6px;">
            
            <div style="display:flex; align-items:center; margin: 0 30px;">
            <div class="btn-group" style="display: flex; flex-direction: row; align-items: stretch">
                <button v-blur @click="setMode('day')" type="button" class="btn" v-bind:class="cfg.mode == 'day' ? 'btn-primary':'btn-light'" style="flex-grow: 1">Day</button>
                <button v-blur @click="setMode('week')" type="button" class="btn" v-bind:class="cfg.mode == 'week' ? 'btn-primary':'btn-light'" style="flex-grow: 1">Week</button>
                <button v-blur @click="setMode('month')" type="button" class="btn" v-bind:class="cfg.mode == 'month' ? 'btn-primary':'btn-light'" style="flex-grow: 1">Month</button>
            </div>
            </div>
            <div style="display:flex; align-items:center; margin: 0 30px;">
            <div class="btn-group" style="display: flex; flex-direction: row; align-items: stretch">
                <button v-blur @click="cfg.shift(-1)" type="button" class="btn btn-light">Previous {{cfg.mode}}</button>
                <button v-blur @click="cfg.shift(+1)" type="button" class="btn btn-light">Next {{cfg.mode}}</button>
            </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
        props: ['cfg'], // a PSP.DateFunctions.DateRangeCfg() // defined in: webapp/static/components/date_component_functions.js
        data: function(){
            return {
            }
        },
        methods: {
            setMode: function(input){
                this.cfg.setMode(input)
            }
        }
}
</script>