<template>
    <div class="sc-map-popup">
        <p class="map-popup-title">
           {{device.nickname}}
        </p>
        <p class="map-popup-img" v-if="photo">
            <img v-bind:src="photo">
        </p>
        <div class="map-popup-alarms" v-if="show_alarms" >
            <div v-for="alarm in current_alarms" v-bind:key="alarm.type" class="mt-1 badge" 
                    v-bind:class="'badge-' + alarm.cssClass" 
                    style="font-size: 90%; width: 100%; display:flex; align-items: baseline; justify-content: flex-start;" 
                    v-bind:title="alarm.time | fromNow">
                <i class="fa fa-fw" v-bind:class="alarm.icon"></i>
                <i class="fa fa-fw" v-bind:class="alarm.typeIcon"></i>
                <span>{{alarm.name}}</span>
            </div>
        </div>
        <p class="map-popup-details" v-if="details" >
            <span v-html="details"></span>
        </p>
        <p>
            <a class="btn btn-info btn-block btn-sm" style="color: white;" v-bind:href="url">Show <i class="fa fa-chevron-right"></i></a>
        </p>
    
        <!-- return '<strong style="font-size: 1.3em;">' + device.nickname + '</strong>' + '<br>' -->
    </div>
</template>

<script>

    function getDeviceDetailsText(device){

        switch(device.scheme){
            case 'flood': {
                var w = _.get(device, "alarms.criteria.floodmgmt.warning")
                var c = _.get(device, "alarms.criteria.floodmgmt.critical")

                var text = ""

                if( _.isNumber(w) ) {
                    text = text + "Warning Alarm at: " + w + "m"
                }
                if(text.length > 0){
                    text = text + "<br>";
                }
                if( _.isNumber(c)){
                    text = text + "Critical Alarm at: " + c + "m"
                }
                
                if(text.length > 0){
                    return text;
                } else {
                    return null;
                }

            }
            default: return null
        }

    }

    export default {
        props: ['device', 'module'],
        data: function(){
            return {
                show_alarms: true // possibly based on scheme or type
            }
        },
        computed: {
            url: function(){ 
                return GLOBALS.makeDeviceURL(this.device, _.get(window, 'PAGE.module.id') || null ) 
            },
            details: function(){
                return getDeviceDetailsText(this.device)
            },
            photo: function(){
                if(this.device.files)
                {
                    var photo = _.find(this.device.files, function(it){ return it.type == 'photo' });
                    if(!photo){
                        return null
                    }
                    var urls = window.SMARTPLACES.urls.files.get_for(this.device._id);
                    return urls.src_with_args({filename: 'th_' + photo.file});
                }
                else {
                    return null;
                }
            },
            current_alarms: function(){
                var vm = this;
                var device = vm.device;

                var modules = [vm.module];
                if(vm.module == 'tempus' || vm.module == 'housing'){
                    modules = ['tempus', 'housing']
                }

                return window.SMART_PROPERTY.getActiveAlarmsForDevice(device, modules);
            }
        }
    }
</script>

<style>
    .sc-map-popup > p.map-popup-title {
        font-size: 1.2em;
        margin-top: 5px;
        margin-bottom: 10px;
        margin-left: 5px;
        margin-right: 5px;
    }
    .sc-map-popup > p.map-popup-img > img {
        width: 100%;
        margin-top: 10px;
        margin-bottom: 10px;
        margin-left: 5px;
        margin-right: 5px;
    }
    .sc-map-popup > p.map-popup-details {
        font-size: 0.7rem;
        color: #6c6c6c;
        margin-top: 10px;
        margin-bottom: 5px;
        margin-left: 5px;
        margin-right: 5px;
    }
</style>