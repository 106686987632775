import Vue from "vue";

import RegEdDevAQ from "../static/components/registry/editor-device-aq.vue";
import RegEdDevBB from "../static/components/registry/editor-device-brighterbins.vue";
import RegEdDevELTRain from "../static/components/registry/editor-device-ELT2-rain.vue";
import RegEdDevFlood from "../static/components/registry/editor-device-flood.vue";
import RegEdDevHousing from "../static/components/registry/editor-device-housing.vue";
import RegEdDevMaint from "../static/components/registry/editor-device-maint.vue";
import RegEdDevMO from "../static/components/registry/editor-device-MetOffice-station.vue";
import RegEdDevRain from "../static/components/registry/editor-device-rain.vue";
import RegEdDevRoad from "../static/components/registry/editor-device-road.vue";
import RegEdDevSEPA from "../static/components/registry/editor-device-SEPA-station.vue";
import RegEdDevTekBin from "../static/components/registry/editor-device-tekelekbin.vue";
import RegEdDevDarkSky from "../static/components/registry/editor-device-darksky_virtual.vue";
import RegEdDevCarparkScheme from "../static/components/registry/editor-device-carpark.vue";
import RegEdDevFootfallScheme from "../static/components/registry/editor-device-footfall.vue";
import RegEdDevTrafficScheme from "../static/components/registry/editor-device-traffic.vue";

Vue.component("editor-device-aq", RegEdDevAQ);
Vue.component("editor-device-brighterbins", RegEdDevBB);
Vue.component("editor-device-ELT2-rain", RegEdDevELTRain);
Vue.component("editor-device-flood", RegEdDevFlood);
Vue.component("editor-device-housing", RegEdDevHousing);
Vue.component("editor-device-maint", RegEdDevMaint);
Vue.component("editor-device-MetOffice-station", RegEdDevMO);
Vue.component("editor-device-rain", RegEdDevRain);
Vue.component("editor-device-road", RegEdDevRoad);
Vue.component("editor-device-SEPA-station", RegEdDevSEPA);
Vue.component("editor-device-tekelekbin", RegEdDevTekBin);
Vue.component("editor-device-darksky_virtual", RegEdDevDarkSky);
Vue.component("editor-device-carpark", RegEdDevCarparkScheme);
Vue.component("editor-device-footfall", RegEdDevFootfallScheme);
Vue.component("editor-device-traffic", RegEdDevTrafficScheme);
