<template>
  <div class="row">
    <div class="col-sm-12 col-md-6 offset-md-3 mb-4">
      <div class="card bg-white border-dark" v-if="entity_type == 'device'">
        <div class="card-header bg-dark text-white">
          <strong>Device Details</strong>
        </div>
        <div class="card-body">
          <table class="table nmt2 table-striped table-bordered">
            <tbody>
              <tr>
                <td>Database ID</td>
                <td>{{ entity._id }}</td>
              </tr>
              <tr>
                <td>Hardware Address (EUI)</td>
                <td>{{ entity.dev_eui }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer bg-dark text-white"></div>
      </div>

      <div class="card bg-white border-dark" v-if="entity_type == 'property'">
        <div class="card-header bg-dark text-white">
          <strong>Device Details</strong>
        </div>
        <div class="card-body">
          <table class="table nmt2 table-striped table-bordered">
            <tbody>
              <tr>
                <td>Database ID</td>
                <td>{{ entity._id }}</td>
              </tr>
              <tr
                v-for="(device, $index) in entity.devices"
                v-bind:key="device._id"
              >
                <td>{{ device.housing.room }}</td>
                <td>oid: {{ device._id }} / eui: {{ device.dev_eui }}</td>
              </tr>

              <tr>
                <td>Weather Reference</td>
                <td>
                  <span v-if="entity.housing.ext_ref">
                    {{ entity.housing.ext_ref }}
                  </span>
                  <span v-else> (Not Assigned) </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer bg-dark text-white"></div>
      </div>
    </div>

    <div class="col-sm-12 col-md-6 offset-md-3 mb-4">
      <div class="card bg-white border-dark">
        <div class="card-header bg-dark text-white">Parameters</div>
        <div class="card-body">
          <table class="table nmt2 table-striped table-bordered">
            <thead>
              <tr>
                <th>Parameter</th>
                <th>Unit</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="editable in user_editable_params"
                v-bind:key="editable.key"
              >
                <td>{{ editable.label }}</td>
                <td>{{ editable.unit }}</td>
                <td>
                  <edit-in-place
                    :device="entity"
                    :field="editable"
                    :type="entity_type"
                  ></edit-in-place>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer bg-dark text-white"></div>
      </div>
    </div>

    <div class="col-sm-12 col-md-6 offset-md-3 mb-4">
      <div class="card bg-white border-dark">
        <div class="card-header bg-dark text-white">
          <strong>Alarm Configuration</strong>
        </div>
        <div class="card-body">
          <table class="table nmt2 table-striped table-bordered">
            <thead>
              <tr>
                <th>Alarm</th>
                <th>Unit</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="editable in user_editable_alarms"
                v-bind:key="editable.key"
              >
                <td>{{ editable.label }}</td>
                <td>{{ editable.unit }}</td>
                <td>
                  <edit-in-place
                    :device="entity"
                    :field="editable"
                    :type="entity_type"
                  ></edit-in-place>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-footer bg-dark text-white"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    entity: Object,
    editable: Object,
    extras: Object,
  },
  methods: {},
  data: function() {
    return {};
  },
  computed: {
    entity_type: function() {
      var vm = this;
      if (vm.entity.type == "property") {
        return "property";
      } else {
        return "device";
      }
    },
    user_editable_params: function() {
      var vm = this;
      var params = vm.editable["params"];
      return pick_and_extend(params, vm.entity);
    },
    user_editable_alarms: function() {
      var vm = this;
      var alarms = vm.editable["alarms"];
      return pick_and_extend(alarms, vm.entity);
    },
  },
};

/*
 * pick the editable (non-readonly ones)
 * and add the current value from the entity object
 */
function pick_and_extend(params, entity) {
  return _(params)
    .pickBy(function(cfg, key) {
      // 1. editable?
      if (cfg.type == "readonly") {
        return false;
      }
      // else
      return true;
    })
    .mapValues(function(cfg, key) {
      cfg.key = key;
      cfg.value = _.get(entity, key);
      return cfg;
    })
    .value();
}
</script>

<style></style>
