<template>
    <!--
            Rain Attributes
    -->
    <div class="editor-device-rain" >
        <fieldset style="margin-top:40px;">
            <legend>Rain / ELT2 with Pronamic Tipping Bucket</legend>

            <div class="row">
                <div class="col-md-12"><h4><em class="text-info">Parameters</em></h4></div>
                <div class="col-md-4">
                    <label for="bmu">mm per tip</label>
                    <input id="bmu" class="form-control" type="number" step="0.1"
                           v-model.number="device.rain.mm_per_tip"/>
                    <span class="errorInfo" v-text="errors['rain.mm_per_tip']"></span>
                </div>
                
            </div>
        </fieldset>
    </div>
</template>

<script>
export default {
    props: ['device', 'errors'],
    data: function() {
        return {}
    }
}
</script>

<style>
</style>