class DateRangeCfg {
    constructor(module) {
        this.module = module || 'default';
        var today = new Date();
        this.reference_date = today;
        this.mode = 'day';
        this.start_date = moment(today).startOf('day').toDate();
        this.end_date = moment(today).endOf('day').toDate();
        this.setMode = function (new_mode) {
            this.mode = new_mode;
            this.update();
            GLOBALS.storePref(this.module + "|" + "last-chart-date-range");
        };
        this.update = function () {
            var vm = this;
            if (vm.mode == 'month') {
                var start_date = moment(vm.reference_date).startOf('month').toDate();
                var end_date = moment(vm.reference_date).endOf('month').toDate();
                vm.start_date = start_date;
                vm.end_date = end_date;
            }
            else if (vm.mode == 'week') {
                var start_date = moment(vm.reference_date).startOf('isoweek').toDate();
                var end_date = moment(vm.reference_date).endOf('isoweek').toDate();
                vm.start_date = start_date;
                vm.end_date = end_date;
            }
            else if (vm.mode = 'day') {
                var start_date = moment(vm.reference_date).startOf('day').toDate();
                var end_date = moment(vm.reference_date).endOf('day').toDate();
                vm.start_date = start_date;
                vm.end_date = end_date;
            }
        };
        this.setDate = function (input) {
            this.reference_date = input;
            this.update();
        };
        
        this.shift = function(n){
            this.setDate(moment(this.reference_date).add(n, this.mode + 's').toDate());
        }

        this.restore = function(){
            this.setMode(GLOBALS.loadPref(this.module + "|" + "last-chart-date-range", 'day'));
        }
    }
}


export default { DateRangeCfg }



