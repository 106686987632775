<template>
    <i class="watch fa" :class="{ 'fa-star': watched, 'fa-star-o':!watched, 'watched':watched }" @click="toggle()" title="Click to set/unset from your watchlist"></i>
</template>

<script>
export default {
        props: ['device_id', 'property_id'],
        computed: {
            icon: function(){
                return this.watched ? 'fa-star' : 'fa-star-o'
            }
        }
        ,data: function(){
            var list = window.SC_WATCHLIST || [];
            var elem = this.getType() + ':' + this.getId();
            var watched = _.includes(list, elem)
            return {
                watched: watched
            }
        },
        methods: {
            toggle: function(){
                var vm = this;
                var url = "/watchlist/" + ( vm.watched ? 'remove':'add' ) + "/" + vm.getType() + "/" + vm.getId();
                axios.get(url).then(function(resp){
                    vm.watched = !vm.watched
                })
            }
            ,
            getType: function(){
                if( _.isString(this.device_id) && this.device_id.length != 0 ){
                    return "device";
                }
                if( _.isString(this.property_id) && this.property_id.length != 0 ){
                    return "property";
                }
                return "unknown"
            }
            ,
            getId: function(){
                if( _.isString(this.device_id) && this.device_id.length != 0 ){
                    return this.device_id;
                }
                if( _.isString(this.property_id) && this.property_id.length != 0 ){
                    return this.property_id;
                }
                return "unknown"
            }
        }
    }
</script>

<style>
 i.watch {
    color: #bdbda9;
 }
 i.watch.watched {
    color: #aeae11;
 }
</style>