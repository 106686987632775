<template>
    <!--
            Housing Attributes
    -->
    <div class="editor-device-housing" >
        <fieldset style="margin-top:40px;">
            <legend>Housing</legend>

            <div class="row">
                <div class="col-sm-6">
                    <label for="txtRoomName">Room Name:</label>
                    <input id="txtRoomName" class="form-control" type="text"
                            v-model="device.housing.room"/>
                    <span class="text-danger" v-text="errors['housing.room']"></span> &nbsp;
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <label for="txtPropertyInfo">Property:</label>
                    <span class="input-group">
                        <span class="input-group-prepend">
                            <a v-bind:href="property_href" class="btn btn-secondary">Show <i class="fa fa-share"></i></a>
                        </span>
                        <input id="txtPropertyInfo" v-bind:value="property_name" class="form-control" readonly type="text"/>
                        <span class="input-group-append">
                            <button class="btn btn-warning" v-on:click="clearProperty()">
                                clear <i class="fa fa-trash"></i>
                            </button>
                            <button class="btn btn-info" v-on:click="showHidePicker(true)">
                                Pick <i class="fa fa-link"></i>
                            </button>
                        </span>
                    </span>
                    <span class="errorInfo" v-text="errors['property_id']"></span> &nbsp;
                </div>
            </div>
        </fieldset>


        <sc-modal v-if="showPicker" @close="showHidePicker(false)" :size="'md'">
            <span slot="title">Choose Property</span>
            <div slot="body">
                <ul>
                    <li v-for="fd in list" v-bind:key="fd._id">
                        <a v-on:click="selectProperty(fd)">{{fd.nickname}}</a>
                    </li>
                </ul>
            </div>
        </sc-modal>

        
    </div>
</template>

<script>

export default {
    props: ['device', 'errors'],
    data: function() {
        return {
            showPicker: false,
            selected: null,
            list: null
        }
    }
    ,
    computed: {
        property_name: function(){
            var vm = this;
            return _.get(vm.selected, 'nickname') || _.get(vm.device, 'property_id');
        },
        property_href: function(){
            var vm = this;
            var pid = _.get(vm.selected, '_id');
            if(!pid){
                return '#'
            } else {
                return '/admin/registry/entities/properties/edit/' + pid
            }
        }
    },
    methods: {
        showHidePicker: function(show){
            var vm = this;
            var api_urls = window.SMARTPLACES.urls.apiv1_registry;
            vm.showPicker = !!show;
            if(vm.list == null || vm.list.length == 0)
            {
                axios.get(api_urls.properties).then(function(resp){
                    Vue.set(vm, 'list', resp.data);
                })
            }
        }
        ,
        selectProperty: function(property){
            var vm = this;
            vm.device.property_id = property._id;
            vm.selected = property;
            vm.showPicker = false;
        }
        ,
        clearProperty: function(){
            var vm = this;
            vm.device.property_id = null;
            vm.selected = null;
        }
    }
    ,
    created: function(){
        var vm = this;
        var api_urls = window.SMARTPLACES.urls.apiv1_registry;
        if(vm.device.property_id){
            axios.get( api_urls.property.replace('PLACEHOLDER:oid', vm.device.property_id ) )
                .then(function(resp){
                    vm.selected = resp.data;
                })
        }
    }
}
</script>

<style>
</style>