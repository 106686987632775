<template>
    <div class="row justify-content-md-center mt-4">
        <div class="col-md-6">
            <div class="alert" v-bind:class="current_status.alertClass">
                <span v-html="prefix"></span> is currently: <strong v-html="current_status.label"></strong> <span v-html="current_status.msg"></span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
        props: ['device', 'offline', 'prefix']
        ,
        computed: {
            current_status: function(){

                if(this.offline){
                    return {
                        level: 'offline',
                        label: 'Offline',
                        msg: 'No Recent data points are avialable from this water level sensor.',
                        alertClass: 'alert-danger'
                    }
                }

                var level = _.get(this.device, 'current_state.alarms.current_level', -1)
                if(level > 80){
                    return {
                        level: 'critical',
                        label: 'Critical',
                        msg: null,
                        alertClass: 'alert-danger'
                    }
                }
                else if(level > 0){
                    return {
                        level: 'warning',
                        label: 'Warning',
                        msg: null,
                        alertClass: 'alert-warning'
                    }
                }
                else if(level == 0) {
                    return {
                        level: 'normal',
                        label: 'Normal',
                        msg: null,
                        alertClass: 'alert-success'
                    }
                }
                else {
                    return {
                        level: 'nodata',
                        label: 'No Data',
                        msg: null,
                        alertClass: 'alert-secondary'
                    }
                }
            },
        },
        methods: {
            
        }
        ,
        data: function(){
            return {}
        }
        ,
        mounted: function(){
            var vm = this;
        }
    }
</script>

<style>
    
</style>