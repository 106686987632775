<template>
  <div v-bind:style="{ height: height }">
    <l-map
      ref="map"
      :zoom="zoom"
      :center="map.center"
      v-bind:style="{ height: height }"
      :bounds="map.bounds"
    >
      <l-tile-layer :url="map.baseMap" :attribution="map.attrib"></l-tile-layer>
      <l-marker
        v-for="d in other_devices"
        v-bind:key="d._id"
        :lat-lng="d.ll"
        :icon="d.marker"
      >
        <l-popup><sc-popup-content :device="d"></sc-popup-content></l-popup>
      </l-marker>
      <l-marker
        v-if="selected && selected.ll"
        :lat-lng="selected.ll"
        :icon="selected.marker"
      >
        <l-popup
          ><sc-popup-content :device="selected"></sc-popup-content
        ></l-popup>
      </l-marker>
    </l-map>
  </div>
</template>

<script>
import { LMap, LTileLayer, LMarker, LPopup, LCircle } from "vue2-leaflet";

/**
 * for a device, return an object which is an extension of the device and some derived mapping properties
 */
function extendWithMappingProperties(device) {
  if (!device) {
    return null;
  }

  var mapProps = {
    ll: L.latLng(device.position.lat, device.position.lon),
    marker: GLOBALS.createPushPinMarker(device),
  };
  return _.assign({}, device, mapProps);
}

export default {
  components: { LMap, LTileLayer, LMarker, LPopup, LCircle },
  props: {
    device: Object,
    devices: Array,
    signal: String,
    height: {
      type: String,
      required: false,
      default: "500px",
    },
    zoom: {
      type: Number,
      required: false,
      default: 10,
    },
    mapClick: Function,
  },
  methods: {
    onShow: function() {
      var vm = this;
      setTimeout(function() {
        vm.$refs.map.mapObject.invalidateSize();
      }, 200);
    },
    setBounds: function() {
      var vm = this;
      var bounds = L.latLngBounds();
      var didsetbounds = false;
      if (vm.selected) {
        didsetbounds = true;
        bounds.extend(vm.selected.ll);
      }
      if (vm.other_devices) {
        _.each(vm.other_devices, (d) => {
          didsetbounds = true;
          bounds.extend(d.ll);
        });
      }

      if (didsetbounds) {
        vm.map.bounds = bounds;
        return true;
      }
      return false;
    },
    setCenter: function() {
      var vm = this;
      if (vm.device) {
        vm.map.center = L.latLng(
          vm.device.position.lat,
          vm.device.position.lon
        );
      } else {
        var c = window.PAGE.instance.mapCenter;
        vm.map.center = L.latLng(c[0], c[1]);
      }
    },
  },
  mounted: function() {
    var vm = this;
    if (vm.selected) {
      vm.setCenter();
    } else {
      vm.setBounds();
    }

    vm.$refs.map.mapObject.invalidateSize(false);

    if (vm.mapClick) {
      vm.$refs.map.mapObject.on("click", function(e) {
        vm.mapClick(e.latlng.lat, e.latlng.lng);
      });
    }

    if (!vm.signal) {
      vm.onShow();
    }
    Vue.eventBus.$on(vm.signal, vm.onShow);
  },
  data: function() {
    var vm = this;
    return {
      message: "Loading...",
      map: {
        baseMap:
          "https://tile.thunderforest.com/neighbourhood/{z}/{x}/{y}{r}.png?apikey=6c2d51dcdb914d11aecb4eb0b83260b4",
        attrib:
          'Maps © <a href="https://www.thunderforest.com">Thunderforest</a>, Data © <a href="http://openstreetmap.org">OpenStreetMap</a> contributors',
        bounds: null,
        center: L.latLng(
          window.PAGE.instance.mapCenter[0],
          window.PAGE.instance.mapCenter[1]
        ),
      },
      selected: extendWithMappingProperties(vm.device),
      other_devices: vm.devices
        ? _(vm.devices)
            .filter(function(d) {
              if (!vm.device) {
                return !!d.position;
              }

              if (d._id != vm.device._id) {
                return !!d.position;
              }

              return false;
            })
            .map(function(d) {
              return extendWithMappingProperties(d);
            })
            .value()
        : [],
    };
  },
  watch: {
    "device.position": {
      handler: function(val, oldVal) {
        var vm = this;
        vm.selected = extendWithMappingProperties(vm.device);
        vm.setCenter();
      },
      deep: true,
    },
  },
};
</script>

<style></style>
