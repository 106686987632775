<template>
    <!--
            MetOffice Attributes
    -->
    <div class="editor-device-rain" >
        <fieldset style="margin-top:40px;">
            <legend>Weather / DarkSky API</legend>

            <div class="row">
                <div class="col-md-12"><h4><em class="text-info">Parameters</em></h4></div>
                <div class="col-md-12 bg-light">
                    <span class="text-danger">1. The dev_eui will be randomly generated</span>
                    <br>
                    <span class="text-danger">2. The Lat/Lon of this virtual device is used to poll for weather data</span>
                    <br>
                    <span class="">Powered by <a href="https://darksky.net/poweredby/">Dark Sky</a></span>
                </div>
            </div>
        </fieldset>
    </div>
</template>

<script>
export default {
    props: ['device', 'errors'],
    data: function() {
        return {}
    }
}
</script>

<style>
</style>