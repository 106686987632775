<template>
    <!--
            AQ Attributes
    -->
    <div class="editor-device-rain" >
        <fieldset style="margin-top:40px;">
            <legend>Aeroqual Device Configuration</legend>

            <div class="row">
                <div class="col-md-12"><h4><em class="text-info">Parameters</em></h4></div>
                <div class="col-md-12 bg-warning">
                    <span class="text-danger">The dev_eui must be the Aeroqual device ID</span>
                    <br>
                    <em>E.g. AQY-BA-358</em>
                </div>
            </div>
        </fieldset>
    </div>
</template>

<script>
export default {
    props: ['device', 'errors'],
    data: function() {
        return {}
    }
}
</script>

<style>
</style>