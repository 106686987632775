<template>
    <!--
            Rain Attributes
    -->
    <div class="editor-device-rain" >
        <fieldset style="margin-top:40px;">
            <legend>Rain</legend>

            <div class="row">
                <div class="col-md-12"><h4><em class="text-info">Parameters</em></h4></div>
                <div class="col-md-12">
                    <span class="text-danger">Some rain devices have specific config.</span>
                </div>
                
            </div>
        </fieldset>
    </div>
</template>

<script>
export default {
    props: ['device', 'errors'],
    data: function() {
        return {}
    }
}
</script>

<style>
</style>